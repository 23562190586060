import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MoneyIcon from "@mui/icons-material/Money";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GradeIcon from '@mui/icons-material/Grade';
import BadgeIcon from '@mui/icons-material/Badge';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DownloadIcon from '@mui/icons-material/Download';
import AboutIcon from '@mui/icons-material/Info';
import logoImg from "../media/../media/icon_logo.png";
import { Container } from "@mui/system";
import {useNavigate, NavLink, useLocation} from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Button,
  Box
} from "@mui/material";
import {useState, useEffect} from "react";
import LoginDialog from "./Signin";

export const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState({
    left: false,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [register, setRegister] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/signup") {
      setRegister(false);
    } else {
      setRegister(true);
    }
  }, [location]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.type === "Tab" || event.type === "Shift")
    ) {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["About Us", "Pricing", "Careers", "Download"].map(
          (text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => navigateToPage(text)}>
                <ListItemIcon>
                  {index === 0 && <AboutIcon />}
                  {index === 1 && <MoneyIcon />}
                  {index === 2 && <BadgeIcon />}
                  {index === 3 && <DownloadIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  const navigateToPage = (page) => {
    if (page  === "Home") {
      navigate("/");
    }
    else if (page  === "Help Center") {
      navigate("/help-center");
    }
    else if (page === "Pricing") {
      navigate("/pricing");
    }
    else if (page === "About Us") {
      navigate("/about-us");
    }
    else if (page === "Careers") {
      navigate("/careers");
    }
    else if (page === "Download") {
      navigate("/download");
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
  };

  const NavbarLinksBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: "pointer",
    display: "none",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  }));

  const NavbarContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#01020a",
    minWidth: "100%",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  }));

  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: 90,
    [theme.breakpoints.down("md")]: {
      width: "40px",
    },
  }));

  return (
    <NavbarContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomMenuIcon sx={{color: "#FFFFFF"}} onClick={toggleDrawer("left", true)} />
          <Drawer
            anchor="left"
            open={mobileMenu["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
          <NavbarLogo onClick={() => navigateToPage("Home")} src={logoImg} alt="logo" />
        </Box>

        <NavbarLinksBox>
          <NavLink to={"/about-us"} variant="body2">About Us</NavLink>
          {/*<NavLink to={"/help-center"} variant="body2">Help Center</NavLink>*/}
          <NavLink to={"/pricing"} variant="body2">Pricing</NavLink>
          <NavLink to={"/careers"} variant="body2">Careers</NavLink>
          <NavLink to={"/download"} variant="body2">Download</NavLink>
        </NavbarLinksBox>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >

        {register && <NavLink to={"/signup"} variant="body2">Sign Up</NavLink>
        }
        <Button
            sx={{backgroundColor: "#0F1B4C", color:"#fff"}}
            variant="contained"
            onClick={handleClickOpen}
        >Login</Button>
      </Box>

      <LoginDialog
          open={openDialog}
          onClose={handleClose}
      />
    </NavbarContainer>
  );
};

export default Navbar;
