import {Box, Button, styled, Typography} from "@mui/material";
import {Container} from "@mui/system";
import React from "react";
import Navbar from "./Navbar";
import Avatar from '@mui/material/Avatar';
import playstore from "../media/playstore.svg";
import apple from "../media/apple.svg";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DownloadHero = () => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const CustomBox = styled(Box)(({theme}) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const Title = styled(Typography)(({theme}) => ({
        fontSize: "64px",
        color: "#000336",
        fontWeight: "bold",
        margin: theme.spacing(4, 0, 4, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
        },
    }));

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );


    return (
        <>
            <Box sx={{
                backgroundColor: "#789292",
                minHeight: "60vh",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: "inset 0 0 0 99999px rgba(120, 120, 255, 0.4)",
            }}>
                <Navbar/>
                <Container>

                    <CustomBox>
                        <Box sx={{flex: "1"}}>
                            <Title
                                variant="h1"
                                sx={{color: "#FFFFFF"}}
                            >
                                Download VeteranApp
                            </Title>
                            <Typography
                                variant="body2"
                                sx={{fontSize: "18px", color: "#DBBE67", my: 4}}
                            >
                                Use VeteranApp across your devices.
                            </Typography>
                            <Button
                                sx={{backgroundColor: "#0F1B4C", color: "#fff", marginRight: "1em", width: "20em", marginTop: "1em",}}
                                variant="contained"
                                startIcon={<Avatar src={apple}/>}
                                onClick={handleClick}
                            >
                                <Box sx={{flex: "1", alignItems: "start"}}>
                                    <Typography
                                        variant="body2"
                                        sx={{fontSize: "8px", color: "#DBBE67", textAlign: "start"}}
                                    >
                                        Download on the
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{fontSize: "18px", color: "#DBBE67", textAlign: "start"}}
                                    >
                                        App Store
                                    </Typography>
                                </Box>

                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "#0F1B4C",
                                    color: "#fff",
                                    marginRight: "1em",
                                    marginTop: "1em",
                                    width: "20em"
                                }}
                                variant="contained"
                                startIcon={<Avatar src={playstore}/>}
                                onClick={handleClick}
                            >
                                <Box sx={{flex: "1"}}>
                                    <Typography
                                        variant="body2"
                                        sx={{fontSize: "8px", color: "#DBBE67", textAlign: "start"}}
                                    >
                                        Download on the
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{fontSize: "18px", color: "#DBBE67", textAlign: "start"}}
                                    >
                                        Google Play
                                    </Typography>
                                </Box>

                            </Button>
                        </Box>

                        {/*<Box sx={{flex: "1"}}>*/}
                        {/*    <img*/}
                        {/*        src={heroImg2}*/}
                        {/*        alt="heroImg"*/}
                        {/*        style={{maxWidth: "100%", marginBottom: "2rem"}}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                    </CustomBox>
                </Container>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Coming soon..."
                    action={action}
                />
            </Box>
        </>
    );
};

export default DownloadHero;
