
export const jobs = [
  {
    id: "1",
    date: "April, 16, 2024",
    category: "IT",
    position: "Lead Mobile Developer",
  },
  {
    id: "3",
    date: "April, 16, 2024",
    category: "IT",
    position: "Kotlin Multi Platform Developer",
  },
  {
    id: "4",
    date: "April, 16, 2024",
    category: "IT",
    position: "Intern React Developer",
  },
  {
    id: "2",
    date: "April, 16, 2024",
    category: "IT",
    position: "Software Engineer",
  },
];

export const jobs1 = [
];

